@use "@angular/material" as mat;

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
    // Large title
    h1 {
        @apply text-xl font-extrabold leading-normal #{!important};
    }

    // Extra bold title
    h2 {
        @apply text-lg font-extrabold leading-normal #{!important};
    }

    // Bold title
    h3 {
        @apply text-lg font-bold leading-normal #{!important};
    }

    // Intertitle
    h4 {
        @apply text-lg font-normal leading-normal #{!important};
    }

    // Link
    h5 {
        @apply text-md font-normal text-accent leading-normal #{!important};
    }

    body {
        @apply text-base font-normal leading-normal;
    }
}

@layer components {
    .mat-mdc-button,
    .mat-mdc-raised-button,
    .mat-mdc-icon-button,
    .mat-mdc-outlined-button,
    .mat-mdc-unelevated-button,
    .mat-mdc-button-base {
        @apply rounded-md text-base font-normal leading-normal gap-4 px-4 text-primary hover:bg-primary/5 #{!important};

        &.button-thin {
            @apply h-8 min-h-8 #{!important};
        }

        &:not(.button-thin) {
            @apply h-11 min-h-11 #{!important};
        }

        &.mat-mdc-icon-button:not(.add-button) {
            @apply w-11 #{!important};
        }

        .mat-mdc-icon-button {
            .mat-mdc-button-persistent-ripple:before {
                @apply hover:bg-accent #{!important};
            }
        }
    }
    .mat-mdc-button-persistent-ripple {
        @apply rounded-none #{!important};
    }

    nice-async-typeahead {
        @apply w-full #{!important};

        > div {
            @apply w-full #{!important};
        }

        .mat-mdc-form-field {
            @apply w-full #{!important};
        }
    }

    .mdc-checkbox,
    .mat-mdc-checkbox,
    mat-radio-button {
        label {
            div,
            span {
                @apply text-base text-primary #{!important};
            }
        }

        &.mdc-checkbox--disabled {
            .mdc-label {
                @apply text-primary #{!important};
            }

            &.mat-mdc-checkbox-checked .mdc-checkbox__background {
                @apply border-transparent bg-primary/50 #{!important};
            }

            &:not(.mat-mdc-checkbox-checked) .mdc-checkbox__background {
                @apply border-primary/50 #{!important};
            }
        }
    }

    .mdc-label,
    .mat-mdc-checkbox .mdc-form-field {
        @apply text-base text-primary;
    }

    .mdc-checkbox__background {
        @apply border-primary #{!important};
    }

    .mat-mdc-raised-button {
        &.mat-primary {
            @apply bg-primary hover:bg-primary-700 text-white #{!important};

            &:disabled {
                @apply bg-primary/50 #{!important};
                * {
                    @apply text-white #{!important};
                }
            }
        }

        &.mat-secondary {
            @apply bg-white text-primary #{!important};
        }

        &.mat-accent {
            @apply text-white #{!important};
        }
    }

    .mat-mdc-unelevated-button {
        &.mat-primary {
            @apply bg-primary hover:bg-primary-700 text-white #{!important};
        }

        &.mat-secondary {
            @apply bg-white text-primary #{!important};
        }

        &.mat-accent {
            @apply text-white hover:bg-accent-700 #{!important};
        }
    }

    .mat-mdc-raised-button:not([class*="mat-elevation-z"]) {
        @apply shadow #{!important};
    }

    .mat-mdc-outlined-button {
        @apply border-primary-500 bg-white text-primary #{!important};

        &.mat-mdc-button-disabled {
            @apply bg-opacity-50 bg-white text-primary #{!important};
        }
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        @apply px-0 py-12 rounded shadow #{!important};
    }

    .nice-chip button {
        @apply h-auto #{!important};

        .mat-icon {
            @apply h-auto #{!important};
        }
    }

    .mat-mdc-option,
    .mat-mdc-option .mdc-list-item__primary-text {
        span {
            @apply w-full #{!important};
        }
    }

    .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
        @apply grow-0 #{!important};
    }
    .mdc-button__label {
        @apply flex;
    }

    .mat-mdc-form-field {
        @include mat.private-form-field-density(-3);

        .mat-mdc-text-field-wrapper:not(.mdc-text-field--invalid) {
            .mdc-notched-outline {
                @apply items-center #{!important};
            }

            .mdc-notched-outline * {
                @apply border-primary/40 #{!important};
            }

            .mat-mdc-form-field-flex {
                @apply items-center h-full #{!important};

                .mat-mdc-form-field-infix * {
                    @apply flex items-center text-primary #{!important};
                }

                .mat-mdc-floating-label {
                    @apply text-primary/75 #{!important};
                }
            }
        }

        &.mat-form-field-disabled {
            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix * {
                @apply text-primary/20 #{!important};
            }
        }

        &.mat-form-field-disabled {
            .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix * {
                @apply text-primary/30 #{!important};
            }

            .mat-mdc-text-field-wrapper:not(.mdc-text-field--invalid) .mdc-notched-outline * {
                @apply border-primary/20 #{!important};
            }
        }
    }

    mat-stepper.mat-stepper-horizontal .mat-horizontal-stepper-wrapper {
        .mat-horizontal-content-container {
            @apply p-0 #{!important};
        }

        .mat-horizontal-stepper-header-container {
            @apply m-auto w-full px-24 items-center justify-center #{!important};

            .mat-stepper-horizontal-line {
                @apply max-w-24 #{!important};
            }

            .mat-step-header:hover,
            .cdk-focused {
                @apply bg-transparent #{!important};
            }

            .mat-step-header.cdk-program-focused {
                @apply bg-transparent #{!important};
            }

            .mat-step-header.cdk-mouse-focused {
                @apply bg-transparent #{!important};
            }

            .mat-step-header.cdk-focused {
                @apply bg-transparent #{!important};
            }

            .mat-step-header {
                @apply cursor-pointer #{!important};

                .mat-step-icon-state-number {
                    &:not(.mat-step-icon-selected) {
                        @apply rounded-full border border-primary bg-transparent text-primary #{!important};
                    }
                }

                .mat-step-text-label {
                    @apply text-primary #{!important};
                }

                .mat-step-label-selected {
                    @apply text-lg #{!important};
                }
            }
        }
    }

    .toggle-button-group {
        .toggle-button {
            .toggle-button-inner {
                @apply text-white #{!important};
            }
        }

        @apply bg-primary-500 bg-opacity-50 #{!important};
    }

    mat-card {
        @apply shadow rounded-md #{!important};
    }

    ngx-panda mat-card mat-card-header .mat-card-header-text mat-card-title div {
        @apply text-xl font-bold #{!important};
    }

    nice-filter-search {
        mat-icon {
            @apply icon-size-5;
        }

        .search {
            @apply pl-4 pr-1.5 #{!important};
        }

        nice-advanced-filters-button {
            .relative {
                @apply h-full #{!important};
            }

            button {
                @apply -top-0.5;
            }

            mat-icon {
                @apply icon-size-5 #{!important};
            }
        }

        input {
            &::placeholder {
                @apply opacity-70 text-default #{!important};
            }
        }
    }

    mat-divider {
        @apply border-t-primary-500/12 border-t-[0.5px] #{!important};
    }

    .mat-mdc-menu-panel {
        @apply min-h-12 max-w-80 #{!important};

        .mat-mdc-menu-content:not(:empty) {
            @apply pt-0 pb-0 #{!important};
        }

        @apply shadow-none #{!important};
        filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));

        button.mat-mdc-menu-item {
            @apply px-6 py-4 #{!important};
        }
    }

    .mat-mdc-form-field
        .mat-mdc-text-field-wrapper:not(.mdc-text-field--invalid)
        .mat-mdc-form-field-flex
        .mat-mdc-form-field-infix {
        & > * > div {
            @apply w-full #{!important};
        }

        .mat-mdc-select-arrow {
            svg {
                @apply hidden #{!important};
            }
        }
    }

    mat-select {
        .mat-mdc-select-arrow {
            @apply h-5 w-5 #{!important};
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 1L8.85333 8.146C8.80696 8.19244 8.75188 8.22929 8.69125 8.25443C8.63062 8.27956 8.56563 8.2925 8.5 8.2925C8.43437 8.2925 8.36938 8.27956 8.30875 8.25443C8.24812 8.22929 8.19304 8.19244 8.14667 8.146L1 1' stroke='%23005787' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;

            svg {
                @apply hidden;
            }
        }
    }

    mat-chip {
        @apply bg-primary rounded-md h-8 #{!important};

        .mat-mdc-chip-action.mdc-evolution-chip__action--primary:before {
            @apply border-0 #{!important};
        }

        nordicite-unit-form {
            @apply text-white #{!important};
        }

        &.disabled {
            @apply bg-primary/50 #{!important};
        }
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--selected) .mdc-evolution-chip__action--primary:before {
        @apply border-0 #{!important};
    }

    mat-tab-group {
        @apply rounded-md;

        .mat-mdc-tab-header {
            @apply p-7 bg-lightBlue-500;

            .mat-mdc-tab:not(.mat-mdc-tab-disabled) {
                .mdc-tab__content {
                    @apply text-lg font-semibold text-primary #{!important};

                    .mdc-tab__text-label {
                        @apply text-primary #{!important};
                    }
                }
            }
        }

        .mat-mdc-tab-body-wrapper {
            @apply shadow;
        }

        .mat-mdc-tab-body {
            .mat-mdc-tab-body-content {
                @apply bg-white rounded-b-md px-8 py-4;
            }
        }
    }
}

.mat-mdc-select-panel {
    @apply min-w-50 #{!important};
}

nice-filter-search .search-wrapper .search {
    @apply leading-normal #{!important};
}
panko-breadcrumb {
    @apply icon-size-3 text-accent #{!important};

    .panko-icon {
        mat-icon {
            @apply text-accent #{!important};
        }
    }

    .panko-item {
        @apply hover:text-primary/80 #{!important};
    }
}

div.mat-mdc-select-panel {
    @apply p-0 #{!important};
}

.mat-mdc-menu-item:not([disabled]):hover,
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item,
.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    @apply bg-hover #{!important};
}

.mat-mdc-autocomplete-panel {
    @apply p-0 #{!important};
}

.cdk-overlay-container {
    & > .cdk-overlay-backdrop.cdk-overlay-backdrop-showing:not(.mat-overlay-transparent-backdrop) {
        @apply bg-primary/25 #{!important};
    }

    & > .cdk-global-overlay-wrapper {
        & > .cdk-overlay-pane {
            .mat-mdc-dialog-container {
                @apply p-0 rounded-[5px] shadow #{!important};
            }
        }
    }
}

.mat-mdc-menu-content {
    span {
        @apply text-base;
    }
}

.mat-datepicker-content {
    @apply shadow #{!important};
}

.mat-expansion-panel-header {
    @apply font-normal #{!important};
}

nice-advanced-rule {
    .mdc-text-field {
        @apply max-h-11 #{!important};
        @apply text-primary bg-white rounded #{!important};
    }

    // The following rule targets the add button and the bin icon in the advanced rule component
    // to align them with the input fields.
    & > div {
        & > div:last-child {
            > div:first-child {
                @apply items-start #{!important};
            }
        }
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    @apply shadow opacity-100 bg-white cursor-ns-resize #{!important};
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    @apply cursor-ns-resize #{!important};
}

.mat-mdc-tooltip {
    @apply whitespace-pre-line;
}

nice-advanced-filters {
    .advanced-filter-title {
        @extend h4;
        @apply font-bold;
    }

    .advanced-filter-condition {
        @apply flex items-center text-base #{!important};
    }

    .mat-mdc-outlined-button:not(.select-filter) {
        @apply text-accent #{!important};
    }

    .close-button {
        @apply text-accent #{!important};
    }

    .mdc-notched-outline {
    }

    .select-filter {
        @apply bg-white #{!important};

        .config-name {
            @apply text-[14px] #{!important};
        }
    }

    button.add-condition {
        @apply h-11 min-h-11 max-h-11 #{!important};
    }
}

.mat-autocomplete-panel.mat-autocomplete-visible {
    @apply min-w-64;
}

.em-dash::after {
    content: "—" !important;
}

ngx-mat-timepicker-standalone {
    @apply bg-white relative -top-[26px] #{!important};

    .ngx-mat-timepicker-standalone-actions .mat-mdc-button {
        @apply bg-transparent hover:bg-primary/5 text-primary #{!important};
    }

    ngx-mat-timepicker-content {
        ngx-mat-timepicker-dial {
            ngx-mat-timepicker-dial-control {
                input {
                    @apply w-18 #{!important};
                }
            }
        }
    }

    .timepicker-dial__control {
        @apply font-bold #{!important};
    }

    input.timepicker-dial__control {
        @apply bg-transparent #{!important};
    }

    timepicker-dial__time:first-child {
        input {
            @apply text-right #{!important};
        }
    }

    timepicker-dial__time:last-child {
        input {
            @apply text-left;
        }
    }
    .mat-primary {
        @apply text-white hover:bg-primary-700 #{!important};
    }
}

mat-slide-toggle {
    .mdc-elevation-overlay {
        @apply bg-primary #{!important};
    }
    .mdc-switch--unselected .mdc-switch__track {
        @apply bg-accent opacity-40 #{!important};
    }
    .mdc-switch--checked .mdc-switch__track {
        @apply bg-accent opacity-60 #{!important};
    }
}

.mdc-checkbox__ripple,
.mat-mdc-checkbox-ripple {
    @apply hidden;
}

.mdc-checkbox__ripple,
.mat-mdc-checkbox-ripple {
    @apply hidden;
}

.mat-tooltip {
    @apply text-sm;
}
