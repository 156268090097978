/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Nice's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@use "@recursyve/nice-ui-kit.v2" as nice;
@use "@recursyve/nice-data-filter-kit" as dataFilter;

@include nice.core();

/*
Firefox text alignment issue:

Firefox calculates text baselines differently than other browsers, so the text is sometimes too high in the text box.
It looks like the font has something to do with it, since removing the font fixes the problem.

https://stackoverflow.com/questions/26385334/css-displayflex-align-itemsbaseline-not-working-in-firefox
 */

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~@syncfusion/ej2-material-theme/styles/material.css";

body.light,
body .light {
    $light-theme: nice.nice-light-theme();
    @include dataFilter.nice-data-filter-kit-theme($light-theme);

    // @GA Had to put the code below here to override the default theme, it somehow doesn't work when I put it in the tailwind.scss file
    div.ng-invalid > mat-checkbox {
        .mdc-checkbox__background {
            @apply border-warn #{!important};
        }

        .mdc-checkbox__background {
            @apply text-warn #{!important};
        }
    }

    .mdc-checkbox__background {
        @apply border-primary #{!important};
    }

    .mdc-switch__track {
        @apply bg-primary-400 #{!important};

        &:before,
        &:after {
            @apply bg-primary-400 #{!important};
        }
    }

    div.ng-invalid > mat-radio-button {
        .mdc-radio__outer-circle {
            @apply border-warn #{!important};
        }
    }

    .mat-accent .mdc-radio__outer-circle {
        @apply border-accent #{!important};
    }

    .mat-primary .mdc-radio__outer-circle {
        @apply border-primary #{!important};
    }
}

/* Classe pour le combobox dans la grille des entrées manuelle.
Les options sont généré au niveau du Body*/
.e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active {
    @apply bg-white text-primary #{!important};
}
.e-dropdownbase .e-list-item.e-hover {
    @apply bg-softBlue-400 #{!important};
}
